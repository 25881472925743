import { TOrderKeys } from "@store/slice/order.slice";
import { useRedStore } from "@store/store";
import { dispatchCase, overrideDefaults, updateCase } from "api/service/case";
import { useMutation } from "react-query";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { queryClient } from "scripts/reactQuery";
import useOrderAction from "@store/action-hook/useOrderAction";
import { convertTo, isValueInvalid, removeNullAndEmptyStrings, showErrorToast, showSuccessToast } from "@utils";
import { useClassifyOrder, useOrderPricing } from "@hooks/api/useCaseApi";
import { AMBULANCE_MAPPER_KEY, PLATFORM_OBJ } from "@config/constant";
import { version } from "../../../package.json";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// const useDispatch = () => {

//   const currentCase = useRedStore(state => state.localOrderChanges);
//   // need to check feature
//   const { loginUser }: any = useAppStore();
//   const { injectPayload } = useFeature();

//   const { mutateAsync, ...rest } = useMutation(dispatchCase, {
//     onSuccess: (data: any) => {
//       console.log("dispatch: ", data);
//     },
//   });

//   const defaultPayloadOptions = {
//     originSource: { source: currentCase.originSource, platform: "HM-APP" },
//     dispatchBy: loginUser?.email,
//   };

//   const dispatchCurrentCase = () => {
//     const payload: any = {
//       ...defaultPayloadOptions,
//     };

//     const keys = currentCase?.billToClient ? AUTO_DISPATCH_ALLOWED_KEY_BTH : AUTO_DISPATCH_ALLOWED_KEY_BTP;

//     keys.forEach(itm => {
//       payload[itm] = currentCase[itm];
//     });

//     prepMedicalReasons(currentCase, payload, setProperty);
//     prepGender(currentCase, payload, setProperty);
//     if (currentCase?.addons?.length > 0) {
//       prepAddons(currentCase, payload, setProperty);
//     }

//     payload["assignmentType"] = "AUTO";
//     payload["bookingType"] = "BOOKING";

//     injectPayload("dispatch-api", payload);

//     mutateAsync(payload);
//   };

//   return { dispatchCurrentCase, ...rest };
// };

interface IDataSelector {
  [key: string]: TOrderKeys[];
}

const dataSelector: IDataSelector = {
  SBE: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
  ],
  STANDARD: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "addons",
    "addonsFare",
    "isLamaDamaSelected",
    "isCovidCase",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
  ],
  DEAD_BODY: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "addons",
    "addonsFare",
    "isCovidCase",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
  ],
  AIR_CARGO: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
    "isOverridenPrice",
  ],
  TRAIN_AMBULANCE: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
    "isOverridenPrice",
  ],
  ORGAN_TRANSPORTATION: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
    // "orderId",
    // "serviceType",
    // "city",
    // "originSource",
    // "attributedTo",
    // "dispatchType",
    // "scheduledDdtm",
    // "waypoints",
    // "entityRequired",
    // "fareAgreed",
    // "orderClassification",
    // "requestedBy",
  ],
  EMBALMING: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
    // "orderId",
    // "serviceType",
    // "city",
    // "originSource",
    // "attributedTo",
    // "dispatchType",
    // "scheduledDdtm",
    // "serviceLocation",
    // "fareAgreed",
    // "orderClassification",
    // "requestedFor",
    // "requestedBy",
  ],
  CREMATION: [
    "orderId",
    "serviceType",
    "city",
    "department",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
  ],
  HOME_FREEZER_BOX: [
    "orderId",
    "department",
    "serviceType",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "billToClient",
    "entityRequired",
    "fareAgreed",
    "orderClassification",
    "requestedBy",

    // "orderId",
    // "serviceType",
    // "city",
    // "originSource",
    // "attributedTo",
    // "dispatchType",
    // "scheduledDdtm",
    // "serviceLocation",
    // "fareAgreed",
    // "orderClassification",
    // "requestedFor",
    // "requestedBy",
  ],
  AIR_AMBULANCE: [
    "orderId",
    "serviceType",
    "department",
    "city",
    "originSource",
    "attributedTo",
    "dispatchType",
    "scheduledDdtm",
    "waypoints",
    "requestedFor",
    "fareAgreed",
    "orderClassification",
    "requestedBy",
  ],
  // _waypoints: ["orderId", "waypoints", "city", "originSource", "attributedTo", "dispatchType", "scheduledDdtm", "bookingType", "orderClassification", "isCovidCase", "requestedBy"],
  _waypoints: ["orderId", "waypoints", "city", "originSource", "attributedTo", "dispatchType", "scheduledDdtm", "bookingType", "requestedBy"],
  _liveData: ["orderId", "waypoints", "city", "originSource", "attributedTo", "dispatchType", "scheduledDdtm", "bookingType", "requestedBy", "isCovidCase", "isLamaDamaSelected", "entityRequired"],
  _patientInfo: ["orderId", "requestedFor", "requestedBy"],
  _additionalDetails: ["orderId", "requestedBy", "requestedFor"],
  _orderClassification: ["orderId", "orderClassification"],
};

export const OVERRIDDEN_SERVICE_TYPE = ["TRAIN_AMBULANCE", "AIR_CARGO", "AIR_AMBULANCE", "HOME_FREEZER_BOX", "CREMATION", "ORGAN_TRANSPORTATION", "SBE", "EMBALMING"];

interface IMutationOptions {
  onSuccess?: (data?: any) => any;
  customPayload?: any;
  fromCasePage?: boolean;
}

const useOrder = () => {
  const { id } = useParams();
  const localOrder: any = useRedStore(state => state.localOrderChanges);
  const addons = useRedStore(state => state.addons);
  const order: any = useRedStore(state => state.order);
  const selectedAddons = useRedStore(state => state.selectedAddons);
  const [nightPrice, setNightPrice] = useState(0)

  const isNightTime = (timestamp?: number) => {
    const date = timestamp ? new Date(timestamp) : new Date();
    const hours = date.getHours();
    return hours >= 22 || hours < 6;
  };

  useEffect(() => {
    if((localOrder?.dispatchType === "SCHEDULED" && localOrder?.scheduledDdtm && isNightTime(localOrder?.scheduledDdtm)) || (localOrder?.dispatchType === "NOW" && isNightTime()) && !localOrder?.isBillToClient)
      setNightPrice(localOrder?.fareAgreed*0.2)
    else 
      setNightPrice(0)
  },[localOrder?.dispatchType, localOrder?.scheduledDdtm, localOrder?.fareAgreed])

  const { __updateOrderWaypointAndSourceData } = useOrderAction();
  // const { execute } = useClassifyOrder();
  const _updateLocalCaseProperty = useRedStore(state => state._updateLocalCaseProperty);

  const { mutateAsync: overrideDefaultsUpdate, ...overrideDefaultsState } = useMutation(overrideDefaults);

  // const tst = toast;
  const { mutateAsync, ...rest } = useMutation(updateCase, {
    onMutate(variables) {
      // toast.loading("Updating your data..");
      // console.log("reqeust start.");
    },
    onSuccess: (data: any) => {
      console.log("reqeust end");
      // toast.success("fetched", {
      //   id: tst
      // });
      // toast.dismiss();
      // toast.success("Update Successfully.");
    },
  });

  // toast.promise(rest.status, {
  //   loading: "Loading",
  //   success: "Got the data",
  //   error: "Error when fetching",
  // });

  const { mutateAsync: _dispatchAsync, ...dispatchStatus } = useMutation(dispatchCase, {
    onSuccess: (data: any) => {
      // console.log("dispatch: ", data);
    },
  });
  const dispatchAsync = debounce(_dispatchAsync, 200);

  const serviceType: any = localOrder?.serviceType || order?.serviceType;

  const initialState = {
    bookingType: order?.bookingType !== "BOOKING" ? "ENQUIRY" : "BOOKING",
  };

  if (localOrder?.orderId && !serviceType) {
    showErrorToast("No service type selected");
    console.log("No service type selected");
    // return;
    // throw Error("No service type selected");
  }

  const keys = dataSelector[serviceType];

  const reducer = (acc: any, key: TOrderKeys) => {
    switch (key) {
      case "isLamaDamaSelected":
        if (localOrder.isLamaDamaSelected) {
          acc["orderClassification"] = "LAMA/DAMA";
          if (!order?.systemOrderClassification) {
            acc["systemOrderClassification"] = localOrder.orderClassification;
          }
          acc["isLamaDamaSelected"] = true;
        } else {
          acc["isLamaDamaSelected"] = false;
          acc["orderClassification"] = localOrder.orderClassification;
        }
        break;
      /**
       * Prepare the requestedFor object
       */

      case "requestedFor":
        acc[key] = {
          ...localOrder?.requestedFor,
          gender: localOrder?.requestedFor?.gender?.value,
          medicalIssue: {
            reason: localOrder[key]?.medicalIssue?.reason?.map((itm: any) => itm.value),
          },
        };
        break;
      /**
       * Prepare scheduledDdtm
       */
      case "originSource":
        if (order?.originSource?.platform !== PLATFORM_OBJ.platform) {
          const platform = order?.originSource?.platform;
          const version = order?.originSource?.version;
          const payload = { ...localOrder?.originSource };

          !!payload["version"] && delete payload["version"];

          if (platform) {
            payload["platform"] = platform;
          }

          if (version) {
            payload["version"] = version;
          }

          acc["originSource"] = payload;
        } else {
          acc["originSource"] = localOrder?.originSource;
        }

        break;
      case "scheduledDdtm":
        if (localOrder[key]) {
          if (typeof localOrder[key] !== "number") {
            const ddtm = new Date(localOrder[key].toISOString()).getTime();
            acc[key] = ddtm;
          } else {
            acc[key] = localOrder[key];
          }
        }
        break;
      /**
       * Prepare the addons Array and addonsFare
       */
      case "addons":
        acc[key] = selectedAddons.map((itm: any) => ({
          type: itm?.type ?? itm?.name,
          key: itm.key,
          price: itm.price || 0,
          quantity: itm.quantity,
          id: itm.id,
          name: itm.name,
          subtypes: itm?.subtypes,
          isCustomPrice: itm?.isCustomPrice,
        }));
        break;
      case "addonsFare":
        acc[key] = parseInt(selectedAddons?.reduce((a, b) => a + b.price, 0) + "");
        break;
      case "version":
        acc[version] = version;
        break;
      /**
       * Default keys
       */
      default:
        if (localOrder[key] !== null || localOrder[key] !== undefined || localOrder[key] !== "") {
          acc[key] = localOrder[key];
        }
        break;
    }
    return acc;
  };

  const payload = keys?.reduce(reducer, initialState);
  const debounceMuted = debounce(mutateAsync, 250);

  const overrideAndUpdateCase = ({ customPayload = {}, onSuccess }: IMutationOptions) => {
    const overridenType = localOrder?.overwriteBillTo === "BTC" ? "BILL_TO_CLIENT" : "BILL_TO_PATIENT";

    const payload = {
      orderId: localOrder?.orderId,
      overridenPrice: convertTo(localOrder?.overwriteFare),
      overridenType,
      overridenComments: localOrder?.overridenComments || localOrder?.serviceType,
      ...customPayload,
    };

    return overrideDefaultsUpdate(payload, {
      onSuccess: () => {
        showSuccessToast("Successfully overriden the price.");
        onSuccess && onSuccess();
      },
    });
  };

  const collectAdvance = async ({ onSuccess }: IMutationOptions) => {
    const isForceOverride = localOrder?.overwriteFare && OVERRIDDEN_SERVICE_TYPE.includes(payload.serviceType) && !order?.isOverridenPrice;

    if (localOrder?.overwriteFare) {
      payload["fareAgreed"] = localOrder?.overwriteFare;
    }

    if (isForceOverride || (localOrder?.isOverridenPrice && !order?.isOverridenPrice)) {
      await overrideAndUpdateCase({});
      delete payload["fareAgreed"];
    }

    for (const itm in payload) {
      if (itm === "orderId") continue;
      if (isValueInvalid(payload[itm]) || isEqual(payload[itm], order[itm])) {
        delete payload[itm];
      }
    }

    if (localOrder?.isLamaDamaSelected) {
      delete payload["orderClassification"];
    }

    if (localOrder?.isOverridenPrice) {
      delete payload["billToClient"];
    }

    if (localOrder?.entityRequired?.[0]?.subtype?.substring(0, 3).toLowerCase() == "als" && ["STANDARD"].includes(localOrder?.serviceType) && !localOrder?.billToClient && localOrder?.dispatchType == "NOW"){
      const defaultAddon:any = addons?.find((addon) => addon.id == "1008_MET")
      payload["fareAgreed"] = payload["fareAgreed"] + defaultAddon?.price;
      payload["meta"] = {...payload["meta"], defaultAddon:defaultAddon}
      
    }
    payload["fareAgreed"] = payload["fareAgreed"] + nightPrice;

    await debounceMuted(
      { ...payload, bookingType: "ENQUIRY" },
      {
        onSuccess: (data: any) => {
          // console.log("Update case: ", data);
          // toast.success("Order Update Successfully!");
          showSuccessToast("Order Update Successfully!");
          onSuccess && onSuccess(data);
        },
      }
    );
  };

  const { execute: getClassifyData } = useClassifyOrder();
  const { execute: getPricingData } = useOrderPricing();

  const multiSetupUpdate = async () => {
    const payload = dataSelector["_liveData"]?.reduce(reducer, {});

    for (const itm in payload) {
      if (itm === "orderId") continue;
      if (payload[itm] === null || payload[itm] === undefined || payload[itm] === "" || isEqual(payload[itm], order[itm])) {
        delete payload[itm];
      }
    }

    // get the classification data.
    // Waypoints => update waypoint => order classification => update order data =>  get pricing
    // lamadama => order classification =>update order data => get pricing
    // covid/city/source/vehicle => update order data => get pricing

    if (payload?.waypoints || payload?.originSource) {
      let classification = localOrder?.orderClassification || order?.orderClassification;
      if (!localOrder.isLamaDamaSelected) {
        classification = await getClassifyData();
      }
      payload["orderClassification"] = classification;
      getPricingData(classification);
    }

    if (payload?.isLamaDamaSelected !== undefined) {
      if (!payload?.classifyOrder || payload?.systemOrderClassification !== undefined) {
        const classification = await getClassifyData();
        payload["orderClassification"] = classification;

        if (payload?.systemOrderClassification !== undefined) {
          payload["systemOrderClassification"] = classification;
        }
      }

      if (payload?.isLamaDamaSelected) {
        payload["orderClassification"] = "LAMA/DAMA";
      }
    }

    if (payload?.isCovidCase !== undefined) {
      getPricingData();
    }

    if (payload?.entityRequired !== undefined) {
      if (Array.isArray(payload?.entityRequired) && payload?.entityRequired?.length <= 0) {
        delete payload["entityRequired"];
      }
      getPricingData();
    }

    for (const itm in payload) {
      if (typeof payload[itm] !== "boolean" && (payload[itm] === undefined || payload[itm] === null || payload[itm] === "")) {
        delete payload[itm];
      }
    }

    if (Object?.keys(payload)?.length <= 1) return null;

    debounceMuted(payload, {
      onSuccess: () => {
        // showSuccessToast("Update Case Details!");
        // get the pricing.
        __updateOrderWaypointAndSourceData(payload);
      },
    });
  };

  const collectAdvanceForOtherType = async ({ onSuccess }: IMutationOptions) => {
    if (!order?.isOverridenPrice) {
      await overrideAndUpdateCase({
        customPayload: {
          overridenType: "BILL_TO_PATIENT",
          overridenPrice: localOrder?.overwriteFare * 100,
        },
      });
    }
    payload["billToClient"] = false;
    payload["isOverridenPrice"] = true;
    delete payload["fareAgreed"];
    delete payload["addonsFare"];

    const finalPayload = removeNullAndEmptyStrings({ ...payload, bookingType: "ENQUIRY" });

    for (const itm in finalPayload) {
      if (itm === "orderId") continue;
      if (isValueInvalid(finalPayload[itm]) || isEqual(finalPayload[itm], order[itm])) {
        delete finalPayload[itm];
      }
    }

    await debounceMuted(finalPayload, {
      onSuccess: (data: any) => {
        // console.log("Update case: ", data);
        showSuccessToast("Order Update Successfully!");
        onSuccess && onSuccess(data);
      },
    });
  };

  const dispatchScheduled = async ({ fromCasePage, onSuccess }: IMutationOptions) => {
    if (localOrder?.billToClient) {
      delete payload["fareAgreed"];
      delete payload["addonsFare"];
    }

    const customPayload = { orderId: payload?.orderId };

    const finalPayload = removeNullAndEmptyStrings(fromCasePage ? { ...payload, ...customPayload } : customPayload);

    for (const itm in finalPayload) {
      if (itm === "orderId") continue;
      if (isValueInvalid(finalPayload[itm]) || isEqual(finalPayload[itm], order[itm])) {
        delete finalPayload[itm];
      }
    }

    if (localOrder?.isOverridenPrice && !order?.isOverridenPrice) {
      await overrideAndUpdateCase({});
    }

    await dispatchAsync(
      { ...finalPayload, bookingType: "BOOKING", assignmentType: "MANUAL", serviceType: payload?.serviceType },
      {
        onSuccess: (data: any) => {
          onSuccess && onSuccess(data);
        },
      }
    );
  };

  const dispatchAmbulance = async ({ ambulance, onSuccess }: any) => {
    const { entityId, ambulanceNumberPlate, ambulanceType, ambulanceSpec } = ambulance;

    const entityRequired = {
      id: entityId,
      ambNumber: ambulanceNumberPlate,
      type: "PILOT",
      partnerLevel: "ONE_P",
      subtype: AMBULANCE_MAPPER_KEY[ambulanceType || ambulanceSpec] || "",
    };

    dispatchAsync(
      { orderId: payload?.orderId, bookingType: "BOOKING", assignmentType: "MANUAL", serviceType: payload?.serviceType, entityRequired: [entityRequired], isFilteredEntity: true },
      {
        onSuccess: (data: any) => {
          onSuccess && onSuccess(data);
        },
      }
    );
  };

  const convertToBooking = async ({ onSuccess }: IMutationOptions) => {
    // TODO: Need to remove this once backend deploy RE-1510
    // { orderId: payload?.orderId, bookingType: "BOOKING", assignmentType: "MANUAL", serviceType: payload?.serviceType },

    const orderId = payload?.orderId || id;

    if (!orderId) {
      showErrorToast("Invalid order ID.");
      return;
    }

    await debounceMuted(
      { orderId: orderId, bookingType: "BOOKING" },
      {
        onSuccess: (data: any) => {
          onSuccess && onSuccess(data);
        },
      }
    );
  };

  const moveToAssignment = async ({ onSuccess }: IMutationOptions) => {
    if (localOrder?.isOverridenPrice && !order?.isOverridenPrice) {
      await overrideAndUpdateCase({});
    }

    if (localOrder?.billToClient) {
      delete payload["fareAgreed"];
      delete payload["addonsFare"];
    }

    const finalPayload = removeNullAndEmptyStrings({ ...payload });

    for (const itm in finalPayload) {
      if (itm === "orderId") continue;
      if (isValueInvalid(finalPayload[itm]) || isEqual(finalPayload[itm], order[itm])) {
        delete finalPayload[itm];
      }
    }

    await debounceMuted(
      { ...finalPayload, bookingType: "BOOKING", assignmentType: "MANUAL", serviceType: payload?.serviceType },
      {
        onSuccess: (data: any) => {
          // console.log("Update case: ", data);
          showSuccessToast("Move to Assignment page.");
          onSuccess && onSuccess(data);
        },
      }
    );
  };

  const syncWaypoint = async () => {
    const payload = dataSelector["_waypoints"]?.reduce(reducer, {});
    // const orderClassification = await execute();

    debounceMuted(
      { ...payload, bookingType: "ENQUIRY" },
      {
        onSuccess: (data: any) => {
          showSuccessToast("Sync Waypoints!");
          __updateOrderWaypointAndSourceData(payload);
        },
        onError: () => {
          // showErrorToast("Unable Sync Waypoints!, please check the address Pin-Code.");
        },
      }
    );
  };

  const updateIpPatientNumber = async ({ ipNumber, onSuccess }: { ipNumber: string; onSuccess?: any }) => {
    const customPayload = {
      orderId: payload.orderId,
      ipPatientNumber: ipNumber,
    };
    debounceMuted(customPayload, {
      onSuccess: () => {
        showSuccessToast("Update IP/UHID Patient Number successfully.");
        queryClient.invalidateQueries([`case-id-${order?.orderId}`]);
        onSuccess && onSuccess();
      },
    });
  };

  const updateCaseOnHold = async ({ isCaseOnHold, onSuccess }: { isCaseOnHold: boolean; onSuccess?: any }) => {
    const customPayload = {
      orderId: payload.orderId,
      isCaseOnHold,
    };
    debounceMuted(customPayload, {
      onSuccess: () => {
        showSuccessToast("Update Case Hold Status successfully.");
        queryClient.invalidateQueries([`case-id-${order?.orderId}`]);
        onSuccess && onSuccess();
      },
    });
  };

  const updateOrderClassification = async (prop: any) => {
    const payload = dataSelector["_orderClassification"]?.reduce(reducer, {});

    debounceMuted(
      { ...payload, ...prop },
      {
        onSuccess: () => {
          showSuccessToast("Update Order Classification Details!");
        },
      }
    );
  };

  const updateAdditionalDetails = async ({ onSuccess }: { onSuccess: any }) => {
    const payload = dataSelector["_additionalDetails"]?.reduce(reducer, {});

    debounceMuted(payload, {
      onSuccess: () => {
        showSuccessToast("Update Patient Details!");
        queryClient.invalidateQueries([`case-id-${order?.orderId}`]);
        onSuccess && onSuccess();
      },
    });
  };

  const updatePatientDetails = async (reqFor: any, reqBy: any) => {
    const payload = dataSelector["_patientInfo"]?.reduce(reducer, {});

    if (payload["requestedBy"]) {
      payload["requestedBy"] = { ...payload["requestedBy"], ...reqBy };
    }

    if (payload["requestedFor"]) {
      payload["requestedFor"] = { ...payload["requestedFor"], ...reqFor };
    }

    // if (!payload["requestedFor"]?.height) {
    //   payload["requestedFor"].height = { feet: null, inches: null };
    // }
    // if (!payload["requestedFor"]?.weight) {
    //   payload["requestedFor"].weight = 0;
    // }

    debounceMuted(payload, {
      onSuccess: () => {
        showSuccessToast("Update Patient Details!");
        queryClient.invalidateQueries([`case-id-${order?.orderId}`]);
      },
    });
  };

  const saveAsEnquiry = async ({ options, onSuccess }: { options?: any; onSuccess?: any }) => {
    const finalPayload = { ...payload, ...options };

    if (localOrder?.isOverridenPrice && !order?.isOverridenPrice) {
      await overrideAndUpdateCase({});
      delete finalPayload["billToClient"];
    }

    for (const itm in finalPayload) {
      if (itm === "orderId") continue;
      if (finalPayload[itm] === null || finalPayload[itm] === undefined || finalPayload[itm] === "" || isEqual(finalPayload[itm], order[itm])) {
        delete finalPayload[itm];
      }
    }

    // if
    if (localOrder?.isLamaDamaSelected) {
      delete finalPayload["orderClassification"];
    }

    await debounceMuted(finalPayload, {
      onSuccess: (data: any) => {
        onSuccess && onSuccess(data);
        showSuccessToast("Order Update Successfully!");
      },
    });
  };

  // const updateAndGetPricing = async ({ keys, onSuccess }: { options?: any; onSuccess?: any }) => {
  //   const payload = dataSelector["_additionalDetails"]?.reduce(reducer, {});

  //   await debounceMuted(
  //     { ...payload, ...options },
  //     {
  //       onSuccess: (data: any) => {
  //         onSuccess && onSuccess(data);
  //         showSuccessToast("Order Update Successfully!");
  //       },
  //     }
  //   );
  // };

  return {
    collectAdvance,
    saveAsEnquiry,
    syncWaypoint,
    moveToAssignment,
    convertToBooking,
    dispatchScheduled,
    dispatchStatus,
    updatePatientDetails,
    updateAdditionalDetails,
    dispatchAmbulance,
    updateOrderClassification,
    collectAdvanceForOtherType,
    updateIpPatientNumber,
    updateCaseOnHold,
    multiSetupUpdate,
    ...{ ...rest, isLoading: rest?.isLoading || overrideDefaultsState?.isLoading },
  };
};

export { useOrder };
